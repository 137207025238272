import React from 'react';
import styled from 'styled-components';

export const SoundcloudIframe = styled.iframe`
	width: 100%;
	height: 600px;
	max-width: 1200px;

	@media only screen and (max-width: 648px) {
		max-height: 600px;
		height: calc(100vh - 80px);
	}
`;
