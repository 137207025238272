import React from 'react';
import { Container } from '../ui/Container';
import { LoadingMessage } from '../LoadingMessage/LoadingMessage';
import { SoundcloudIframe } from './styled';

const url =
  'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/208097206&color=%238000ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true';

const Soundcloud = () => (
  <Container maxWidth={1200}>
    <LoadingMessage />
    <SoundcloudIframe src={url} scrolling="no" frameborder="0" />
    {/* <iframe width="100%" height="600" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/208097206&color=%238000ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> */}
  </Container>
);

export { Soundcloud };
