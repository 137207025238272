import React from 'react';

import { Site } from '../components/Site';
import { Container } from '../components/ui/Container';
import { Soundcloud } from '../components/Soundcloud';

const seo = {
  title: 'Songs',
};

const SongsPage = ({ ...other }) => {
  return (
    <Site seo={seo} {...other}>
      <Container maxWidth={1600}>
        <Soundcloud />
      </Container>
    </Site>
  );
};

export default SongsPage;
